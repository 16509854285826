import React, { useState, Fragment } from 'react'

import Select from 'react-select'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import AddClassesTable from 'components/admin/AddClassesTable'
// import {DataReader} from 'components/admin/ParaPhrase'

const AddGroupsPage = () => {
	const [tableCSV] = useState([])
	const [tableData] = useState([
		{
			id: 1,
			name: 'Group ABC',
			year: 'FY',
			status: 'success',
			statusIcon: 'success',
		},
		{
			id: 2,
			name: 'Group XYZ',
			year: 'SY',
			status: 'success',
			statusIcon: 'success',
		},
		{
			id: 3,
			name: 'Group 123',
			year: 'TY',
			status: 'success',
			statusIcon: 'success',
		},
		{
			id: 4,
			name: 'Group 789',
			year: 'TY',
			status: 'fail',
			statusIcon: 'fail',
		},
	])
	const columns = [
		{
			text: 'Id',
			dataField: 'id',
			sort: true,
		},
		{
			text: 'Name',
			dataField: 'name',
			sort: true,
		},
		{
			text: 'Year',
			dataField: 'year',
			sort: true,
		},
		{
			text: 'Status',
			dataField: 'status',
			sort: true,
		},
		{
			text: 'Status',
			dataField: 'statusIcon',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: (e) => {
				// console.log(e)
				if (e === 'success') {
					return <i className='fa fa-check text-success'></i>
				} else {
					return <i className='fa fa-times text-danger'></i>
				}
			},
		},
	]
	const [categories] = useState([])
	const [subCategories] = useState([])
	const [show, setShow] = useState(false)

	// React.useEffect(() => {
	// 	ApiLinks.get_courses().then((data) => {
	// 		console.log(data)
	// 		data = data.map((elem) => {
	// 			const updated = {
	// 				name: elem.course_name,
	// 				id: elem.uid,
	// 				year: elem.category ?? '',
	// 				status: elem.uid ? 'success' : 'failed',
	// 				statusIcon: { data: elem.uid ? 'success' : 'fail', uid: elem.uid },
	// 			}
	// 			// console.log(updated)
	// 			return updated
	// 		})
	// 		console.log(data)
	// 		setTableData(data)
	// 	})
	// }, [show])

	const handleClose = () => {
		// console.log(modalData)
		setShow(false)
	}
	const handleShow = () => setShow(true)

	const [modalData, setModalData] = useState({
		category: '',
		subCategory: '',
		course_name: '',
		description: '',
	})
	return (
		<Fragment>
			<Fragment>
				<div className='row mt-4 mx-2'>
					<div className='col-3'>
						<h2>Add Groups</h2>
					</div>
					<div className='col-9 px-2'>
						<div className='float-right'>
							<button onClick={() => window.open('/course_sample_updated.csv')} className='btn btn-primary'>
								<i className='fa fa-download px-2 '></i>
								<span className='pl-2'>Download Sample File</span>
							</button>
						</div>
						<div className='float-right px-2'>
							<button className='btn btn-primary' onClick={handleShow}>
								<i className='fa fa-plus px-2'></i>
								<span className='pl-2'>Add Course</span>
							</button>
						</div>
					</div>
				</div>
				<div className='row mt-4 mx-2'>
					<div className='col-12'>
						<div className='card'>
							<form>
								<div className='card-header bg-white'>
									{/* <FilePond
									labelIdle={`<span className="${icon} iconSpan"></span> ${uploadTitle} or <span className="filepond--label-action">Browse</span>`}
									buttonTitle='Submit Assignment'
									allowMultiple={false}
									dropOnPage={true}
									allowPaste={true}
									// server={server}
									name={'file'}
								/> */}
								</div>
								<div className='card-body'>
									<AddClassesTable data={tableData} columns={columns} />
								</div>
							</form>
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg' centered>
					<Modal.Header closeButton>
						<Modal.Title>Add Course</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Form>
							<Row>
								<Col lg={6}>
									<Form.Group controlId='txtTeacherName'>
										<Form.Label>Category</Form.Label>
										<Select
											options={categories}
											onChange={(e) => {
												setModalData({
													...modalData,
													category: e.value,
												})
												//TODO: Populate Subcategory List from API call
												//setSubcategory([]);
											}}
										/>
									</Form.Group>
								</Col>
								<Col lg={6}>
									<Form.Group controlId='txtTeacherName'>
										<Form.Label>Sub-Category</Form.Label>
										<Select
											options={subCategories}
											onChange={(e) => {
												setModalData({
													...modalData,
													subCategory: e.value,
												})
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<Form.Group controlId='txtcourse_name'>
										<Form.Label>Course Name</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter Course Name'
											onChange={(e) => {
												setModalData({
													...modalData,
													course_name: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<Form.Group controlId='txtdescription'>
										<Form.Label>Course Description</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter Course Description'
											onChange={(e) => {
												setModalData({
													...modalData,
													description: e?.target?.value,
												})
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Modal.Body>

					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Close
						</Button>
						<Button
							// onClick={async () => {
							// 	try {
							// 		await ApiLinks.add_course(modalData)
							// 		handleClose()
							// 	} catch (err) {
							// 		console.err(err)
							// 	}
							// }}
							variant='primary'
						>
							Save changes
						</Button>
					</Modal.Footer>
				</Modal>
				<br />
				<br />
				<br />
				<br />
				<h1>Add Groups CSV</h1>
				{/* <DataReader
							onLoad={(state) => {
								// console.log({ state })
								const l = []
								for (let outer = 1; outer < state.length; outer++) {
									const elem = {}
									for (let index = 0; index < state[0].data.length; index++) {
										const key = state[0].data[index]?.trim()
										elem[key] = state[outer].data[index]?.trim()
									}
									l.push(elem)
								}
								console.log({ l })

								setTableCSV(l)
							}}
						></DataReader> */}

				<table>
					{tableCSV.length > 0 && (
						<AddClassesTable
							data={tableCSV}
							columns={[
								{
									text: 'course_name',
									dataField: 'course_name',
									sort: true,
								},
								{
									text: 'category',
									dataField: 'category',
									sort: true,
								},
								{
									text: 'description',
									dataField: 'description',
									sort: true,
								},
							]}
						/>
					)}
					{/* {tableCSV.map((elem) => {
							return (
								<Fragment>
									<tr>
										<td>{elem['course_name']}</td>
										<td>{elem['category']}</td>
										<td>{elem['description']}</td>
									</tr>
								</Fragment>
							)
						})} */}
					<button
					// onClick={async () => {
					// 	console.log(tableCSV)

					// 	tableCSV.forEach(async (elem) => {
					// 		try {
					// 			console.log((await ApiLinks.add_course(elem)).data)
					// 		} catch (err) {
					// 			console.error(err)
					// 		}
					// 	})
					// }}
					>
						Submit
					</button>
				</table>
			</Fragment>
		</Fragment>
	)
}

export default AddGroupsPage
